export default class Weeks {
    handleGetWeeksIntensivao() {
        return {
            "week1": {
                "Matemática e suas Tecnologias": [
                    {
                        "title": "Notação Científica",
                        "moduleTitle": "Conhecimentos Numéricos",
                        "link": "/curso/1974/modulo/8665/aula/70044"
                    },
                    {
                        "title": "Equações do 1º Grau",
                        "moduleTitle": "Conhecimentos Algébricos",
                        "link": "/curso/1977/modulo/8702/aula/70053"
                    },
                    {
                        "title": "Razões e Proporções",
                        "moduleTitle": "Conhecimentos Numéricos",
                        "link": "/curso/1974/modulo/8672/aula/69720"
                    },
                    {
                        "title": "Porcentagem e Juros",
                        "moduleTitle": "Conhecimentos Numéricos",
                        "link": "/curso/1974/modulo/8673/aula/69681"
                    }
                ],
                "Ciências da Natureza e suas Tecnologias": [
                    {
                        "title": "Movimento Uniforme",
                        "moduleTitle": "Mecânica - Cinemática",
                        "link": "/curso/1963/modulo/8599/aula/73036"
                    },
                    {
                        "title": "Movimento Uniformemente Variado",
                        "moduleTitle": "Mecânica - Cinemática",
                        "link": "/curso/1963/modulo/8600/aula/74670"
                    },
                    {
                        "title": "Conceituação de Trabalho",
                        "moduleTitle": "Mecânica - Energia e Impulso",
                        "link": "/curso/1965/modulo/8610/aula/69970"
                    },
                    {
                        "title": "Energia Cinética",
                        "moduleTitle": "Mecânica - Energia e Impulso",
                        "link": "/curso/1965/modulo/8611/aula/71299"
                    },
                    {
                        "title": "Energia Potencial Gravitacional e Elástica",
                        "moduleTitle": "Mecânica - Energia e Impulso",
                        "link": "/curso/1965/modulo/8612/aula/72729"
                    },
                    {
                        "title": "Citoplasma",
                        "moduleTitle": "Citologia: o Estudo da Célula",
                        "link": "/curso/1991/modulo/8825/aula/69611"
                    },
                    {
                        "title": "Átomo e Sua Estrutura",
                        "moduleTitle": "Transformações Químicas",
                        "link": "/curso/1982/modulo/8756/aula/69610"
                    }
                ],
                "Linguagens, Códigos e suas Tecnologias": [
                    {
                        "title": "Literatura e Processo Social",
                        "moduleTitle": "Teoria da Literatura",
                        "link": "/curso/2007/modulo/8913/aula/70240"
                    },
                    {
                        "title": "Relação entre textos",
                        "moduleTitle": "Interpretação de Texto",
                        "link": "/curso/2012/modulo/8951/aula/70190"
                    }
                ],
                "Ciências Humanas e suas Tecnologias": [
                    {
                        "title": "Expansão Marítima",
                        "moduleTitle": "História Geral - Idade Moderna",
                        "link": "/curso/2040/modulo/9038/aula/70404"
                    },
                    {
                        "title": "O Poder da Política",
                        "moduleTitle": "Organizações e Movimentos Sociais e Políticos",
                        "link": "/curso/2070/modulo/9247/aula/69952"
                    },
                    {
                        "title": "Cartografia",
                        "moduleTitle": "Representação Espacial",
                        "link": "/curso/2048/modulo/9122/aula/69655"
                    },
                    {
                        "title": "Conhecimento e Verdade na Filosofia Medieval",
                        "moduleTitle": "Posso conhecer a verdade?",
                        "link": "/curso/1974/modulo/8673/aula/69681"
                    }
                ],
                "Correção de Redação": [
                    {
                        "title": "Projeto de Texto",
                        "moduleTitle": "Outros Vestibulares - Recursos Argumentativos",
                        "link": "/curso/2022/modulo/9001/aula/74652"
                    }
                ]
            },
            "week2": {
                "Linguagens, Códigos e suas Tecnologias": [
                    {
                        "title": "Gêneros Literários",
                        "moduleTitle": "Teoria da Literatura",
                        "link": "/curso/2007/modulo/8914/aula/70184"
                    },
                    {
                        "title": "Funções da Linguagem",
                        "moduleTitle": "Interpretação de Texto",
                        "link": "/curso/2012/modulo/8950/aula/70362"
                    }
                ],
                "Ciências Humanas e suas Tecnologias": [
                    {
                        "title": "Movimentos Sociais",
                        "moduleTitle": "Organizações e Movimentos Sociais e Políticos",
                        "link": "/curso/2070/modulo/9251/aula/70134"
                    },
                    {
                        "title": "Independência do Brasil",
                        "moduleTitle": "História do Brasil - Brasil Colônia",
                        "link": "/curso/2042/modulo/9087/aula/70142"
                    },
                    {
                        "title": "Conhecimento e Verdade na Filosofia Moderna",
                        "moduleTitle": "Posso conhecer a verdade?",
                        "link": "/curso/2063/modulo/9202/aula/69751"
                    },
                    {
                        "title": "Geosfera",
                        "moduleTitle": "Natureza e a Relação do Ser Humano com o Ambiente",
                        "link": "/curso/2049/modulo/9123/aula/69753"
                    }
                ],
                "Ciências da Natureza e suas Tecnologias": [
                    {
                        "title": "Ligações Químicas",
                        "moduleTitle": "Transformações Químicas",
                        "link": "/curso/1982/modulo/8757/aula/69628"
                    },
                    {
                        "title": "Sistema Cardiovascular ou Circulatório",
                        "moduleTitle": "Fisiologia Animal e Humana: os Sistemas do Corpo",
                        "link": "/curso/1995/modulo/8839/aula/69763"
                    },
                    {
                        "title": "Sistemas Conservativos e Potência",
                        "moduleTitle": "Mecânica - Energia e Impulso",
                        "link": "/curso/1965/modulo/8613/aula/73554"
                    },
                    {
                        "title": "Movimento Circular Uniforme",
                        "moduleTitle": "Mecânica - Cinemática",
                        "link": "/curso/1963/modulo/8601/aula/73264"
                    }
                ],
                "Matemática e suas Tecnologias": [
                    {
                        "title": "Frações, Decimais e Dízimas",
                        "moduleTitle": "Conhecimentos Numéricos",
                        "link": "/curso/1974/modulo/8663/aula/69772"
                    },
                    {
                        "title": "Grandezas e Unidades de Medidas",
                        "moduleTitle": "Conhecimentos Geométricos",
                        "link": "/curso/1975/modulo/8676/aula/70318"
                    },
                    {
                        "title": "Ângulos",
                        "moduleTitle": "Conhecimentos Geométricos",
                        "link": "/curso/1975/modulo/8677/aula/70034"
                    },
                    {
                        "title": "Triângulos",
                        "moduleTitle": "Conhecimentos Geométricos",
                        "link": "/curso/1975/modulo/8677/aula/70034"
                    }
                ],
                "Correção de Redação": [
                    {
                        "title": "Estrutura do parágrafo de introdução",
                        "moduleTitle": "ENEM - Tema e Gênero",
                        "link": "/curso/2019/modulo/8995/aula/74378"
                    }
                ]
            },
            "week3": {
                "Linguagens, Códigos e suas Tecnologias": [
                    {
                        "title": "História Literária",
                        "moduleTitle": "Teoria da Literatura",
                        "link": "/curso/2007/modulo/8915/aula/69889"
                    },
                    {
                        "title": "Variações Linguísticas",
                        "moduleTitle": "Variações Linguísticas",
                        "link": "/curso/2014/modulo/8971/aula/69738"
                    }
                ],
                "Ciências Humanas e suas Tecnologias": [
                    {
                        "title": "O Estudo da Antropologia",
                        "moduleTitle": "Diversidade Cultural, Conflitos e Sociedade",
                        "link": "/curso/2069/modulo/9231/aula/70389"
                    },
                    {
                        "title": "Escravidão Negra no Brasil",
                        "moduleTitle": "Diversidade Cultural, Conflitos e Sociedade",
                        "link": "/curso/2045/modulo/9109/aula/70440"
                    },
                    {
                        "title": "Conhecimento e Verdade na Filosofia Contemporânea",
                        "moduleTitle": "Posso conhecer a verdade?",
                        "link": "/curso/2063/modulo/9203/aula/69635"
                    },
                    {
                        "title": "Biosfera",
                        "moduleTitle": "Natureza e a Relação do Ser Humano com o Ambiente",
                        "link": "/curso/2049/modulo/9126/aula/69702"
                    }
                ],
                "Ciências da Natureza e suas Tecnologias": [
                    {
                        "title": "Estrutura e Propriedades I",
                        "moduleTitle": "Transformações Químicas",
                        "link": "/curso/1982/modulo/8758/aula/69620"
                    },
                    {
                        "title": "Padrões entre os seres vivos",
                        "moduleTitle": "Reino Metazoa: os Animais (Zoologia)",
                        "link": "/curso/1998/modulo/8866/aula/70002"
                    },
                    {
                        "title": "Evolução",
                        "moduleTitle": "Origem da Vida e Evolução",
                        "link": "/curso/2003/modulo/8889/aula/69671"
                    },
                    {
                        "title": "Lançamento de Projéteis",
                        "moduleTitle": "Mecânica - Cinemática",
                        "link": "/curso/1963/modulo/8603/aula/69679"
                    },
                    {
                        "title": "Eletrodinâmica - Corrente, Potência e Resistores",
                        "moduleTitle": "Fenômenos Elétricos e Magnéticos",
                        "link": "/curso/1967/modulo/8620/aula/69893"
                    }
                ],
                "Matemática e suas Tecnologias": [
                    {
                        "title": "Quadriláteros Notáveis",
                        "moduleTitle": "Conhecimentos Geométricos",
                        "link": "/curso/1975/modulo/8679/aula/70086"
                    },
                    {
                        "title": "Polígonos Regulares",
                        "moduleTitle": "Conhecimentos Geométricos",
                        "link": "/curso/1975/modulo/8680/aula/69865"
                    },
                    {
                        "title": "Círculo e Circunferência",
                        "moduleTitle": "Conhecimentos Geométricos",
                        "link": "/curso/1975/modulo/8681/aula/70083"
                    },
                    {
                        "title": "Prismas",
                        "moduleTitle": "Conhecimentos Geométricos",
                        "link": "/curso/1975/modulo/8687/aula/69774"
                    }
                ],
                "Correção de Redação": [
                    {
                        "title": "Elaboração de tese",
                        "moduleTitle": "ENEM - Defesa do Ponto de Vista",
                        "link": "/curso/2020/modulo/8996/aula/69673"
                    }
                ]
            },
            "week4": {
                "Ciências da Natureza e suas Tecnologias": [
                    {
                        "title": "Termoquímica",
                        "moduleTitle": "Transformações Químicas e Energia",
                        "link": "/curso/1985/modulo/8776/aula/69703"
                    },
                    {
                        "title": "Matéria e Energia nos Ecossistemas",
                        "moduleTitle": "Ecologia",
                        "link": "/curso/2004/modulo/8893/aula/71795"
                    },
                    {
                        "title": "Calorimetria",
                        "moduleTitle": "ENEM - Defesa do Ponto de Vista",
                        "link": "/curso/1968/modulo/8625/aula/71657"
                    }
                ],
                "Ciências Humanas e suas Tecnologias": [
                    {
                        "title": "As Desigualdades (Parte 2)",
                        "moduleTitle": "Diversidade Cultural, Conflitos e Sociedade",
                        "link": "/curso/2069/modulo/9237/aula/73545"
                    },
                    {
                        "title": "Pensamento Científico na Filosofia Moderna",
                        "moduleTitle": "Como entender a ciência?",
                        "link": "/curso/2064/modulo/9207/aula/69766"
                    },
                    {
                        "title": "2ª Guerra Mundial",
                        "moduleTitle": "História Geral - Idade Contemporânea",
                        "link": "/curso/2041/modulo/9067/aula/70051"
                    },
                    {
                        "title": "Agropecuária",
                        "moduleTitle": "Estruturas Produtivas",
                        "link": "/curso/2050/modulo/9131/aula/69882"
                    }
                ],
                "Linguagens, Códigos e suas Tecnologias": [
                    {
                        "title": "Arte Contemporânea",
                        "moduleTitle": "Arte na Idade Contemporânea",
                        "link": "/curso/2077/modulo/9297/aula/69782"
                    },
                    {
                        "title": "Macroestrutura semântica",
                        "moduleTitle": "Estudo dos Aspectos Linguísticos nos textos",
                        "link": "/curso/2015/modulo/8972/aula/70527"
                    },
                    {
                        "title": "Modernismo no Brasil - 1ª Fase",
                        "moduleTitle": "Escolas Literárias",
                        "link": "/curso/2008/modulo/8928/aula/70187"
                    },
                    {
                        "title": "Modernismo no Brasil - 2ª Fase",
                        "moduleTitle": "Escolas Literárias",
                        "link": "/curso/2008/modulo/8929/aula/69778"
                    },
                    {
                        "title": "Modernismo no Brasil - 3ª Fase",
                        "moduleTitle": "Escolas Literárias",
                        "link": "/curso/2008/modulo/8930/aula/70215"
                    }
                ],
                "Matemática e suas Tecnologias": [
                    {
                        "title": "Análise Combinatória",
                        "moduleTitle": "Conhecimentos de Estatística e Probabilidade",
                        "link": "/curso/1976/modulo/8695/aula/69840"
                    },
                    {
                        "title": "Noções de Probabilidade",
                        "moduleTitle": "Conhecimentos de Estatística e Probabilidade",
                        "link": "/curso/1976/modulo/8696/aula/69830"
                    },
                    {
                        "title": "Representação e Análise de Dados",
                        "moduleTitle": "Conhecimentos de Estatística e Probabilidade",
                        "link": "/curso/1976/modulo/8697/aula/70357"
                    },
                    {
                        "title": "Medidas para Dados Simples",
                        "moduleTitle": "Conhecimentos de Estatística e Probabilidade",
                        "link": "/curso/1976/modulo/8698/aula/70403"
                    }
                ],
                "Correção de Redação": [
                    {
                        "title": "Argumentação",
                        "moduleTitle": "ENEM - Defesa do Ponto de Vista",
                        "link": "/curso/2020/modulo/8997/aula/70010"
                    }
                ]
            },
            "week5": {
                "Ciências da Natureza e suas Tecnologias": [
                    {
                        "title": "Pilhas Eletroquímicas",
                        "moduleTitle": "Transformações Químicas e Energia",
                        "link": "/curso/1985/modulo/8778/aula/69689"
                    },
                    {
                        "title": "Impactos e Problemas Ambientais",
                        "moduleTitle": "Ecologia",
                        "link": "/curso/2004/modulo/8896/aula/69946"
                    },
                    {
                        "title": "Ondas - Conceitos Fundamentais",
                        "moduleTitle": "Oscilações e Ondas",
                        "link": "/curso/1970/modulo/8637/aula/70402"
                    }
                ],
                "Ciências Humanas e suas Tecnologias": [
                    {
                        "title": "O Nascimento da Sociologia",
                        "moduleTitle": "Organizações e Movimentos Sociais e Políticos",
                        "link": "/curso/2070/modulo/9244/aula/69732"
                    },
                    {
                        "title": "Urbanização",
                        "moduleTitle": "Organizações e Movimentos Sociais e Políticos",
                        "link": "/curso/2051/modulo/9134/aula/69608"
                    },
                    {
                        "title": "Era Vargas",
                        "moduleTitle": "História do Brasil - República",
                        "link": "/curso/2044/modulo/9098/aula/69826"
                    },
                    {
                        "title": "Ética e Política na Filosofia Moderna",
                        "moduleTitle": "Como entender a sociedade?",
                        "link": "/curso/2065/modulo/9212/aula/70365"
                    }
                ],
                "Matemática e suas Tecnologias": [
                    {
                        "title": "Cilindros",
                        "moduleTitle": "Conhecimentos Geométricos",
                        "link": "/curso/1975/modulo/8689/aula/69709"
                    },
                    {
                        "title": "Funções Trigonométricas",
                        "moduleTitle": "Conhecimentos Algébricos",
                        "link": "/curso/1977/modulo/8720/aula/69666"
                    },
                    {
                        "title": "Funções Algébricas do 1º Grau",
                        "moduleTitle": "Conhecimentos Algébricos",
                        "link": "/curso/1977/modulo/8708/aula/69755"
                    },
                    {
                        "title": "Funções Algébricas do 2º Grau",
                        "moduleTitle": "Conhecimentos Algébricos",
                        "link": "/curso/1977/modulo/8709/aula/74156"
                    }
                ],
                "Linguagens, Códigos e suas Tecnologias": [
                    {
                        "title": "Figuras de Linguagem",
                        "moduleTitle": "Estudo dos Aspectos Linguísticos nos textos",
                        "link": "/curso/2015/modulo/8973/aula/70109"
                    },
                    {
                        "title": "Literatura contemporânea",
                        "moduleTitle": "Escolas Literárias",
                        "link": "/curso/2008/modulo/8931/aula/72079"
                    }
                ],
                "Correção de Redação": [
                    {
                        "title": "Elementos da proposta de intervenção",
                        "moduleTitle": "ENEM - Proposta de Intervenção",
                        "link": "/curso/2021/modulo/8999/aula/70224"
                    }
                ]
            }
        }
    }
}
